import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0002 = (props: Props): JSX.Element => {
  const routePram = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0068/'
  )
  const routePram2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routePram3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routePram4 = useRouteParam('https://www.kintaicloud.jp/')
  const routePram5 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理とは？管理をする必要性や管理体制の整備について解説 " />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2022.04.15</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <p>
          勤怠管理とは、
          <span>
            労働日ごとの出勤時間・退勤時間・休憩時間など、従業員の勤務状況を記録し管理する
          </span>
          ことです。この勤怠管理は、会社の規模や業種にかかわらず、会社内で必ず行わなければなりません。ワーク・ライフ・バランスや働き方改革、そして新型コロナウイルス感染症流行拡大の影響により在宅勤務が一般的になるなど、近年、
          <span>社員一人ひとりの働き方が急速に多様化</span>
          しました。また労働時間にまつわる労使トラブルも後を絶たない中、適切な勤怠管理がこれまで以上に求められています。
          <br />
          <br />
          今回は、勤怠管理とはそもそもどのような内容か、具体的な方法や就業管理との違い、未来に向けた勤怠管理体制の整備について、順に解説をしていきましょう。
        </p>
        <img src="/images/column/details/c0002.jpg" alt="キンクラコラム" />
        <div className="toc">
          <p>目次</p>
          <ol>
            <li>
              <a href="#toc1">勤怠管理とは？その目的と必要性</a>
            </li>
            <li>
              <a href="#toc2">勤怠管理の方法には何があるか</a>
            </li>
            <li>
              <a href="#toc3">勤怠管理システムのメリット・デメリット</a>
            </li>
            <li>
              <a href="#toc4">就業管理との違いは？</a>
            </li>
            <li>
              <a href="#toc5">勤怠管理が必要とされる理由</a>
            </li>
            <li>
              <a href="#toc6">勤怠管理体制の整備</a>
            </li>
            <li>
              <a href="#toc7">まとめ</a>
            </li>
          </ol>
        </div>
        <div id="toc1">
          <h1>勤怠管理とは？その目的と必要性</h1>
        </div>
        <p>
          勤怠管理を行う目的は、社員が決められた労働時間を守っているか、労働基準法などの法律に違反した働き方をしていないか確認するため、また、正しい賃金計算を実施するためです。
          <br />
          具体的には、社員が何時から何時まで働いているのか、休憩時間をどれだけ取っているのか、残業はいつ何時まで行っているのか、深夜労働をしているのか、休日出勤をしているのか、有給休暇を取得したのはいつか、などの情報を記録していきます。
          <br />
          給与計算の際、時間外労働、深夜労働、休日労働については割増賃金を支払うことが法律で義務付けられているため、
          <span>労働時間数だけでなく、時間帯の把握</span>も必要です。
        </p>
        <p>
          ↓ 勤怠管理の必要性についてこちらの記事でも詳しく解説しています。
          <br />
          <a href={routePram} className="column-detail-link">
            関連記事：勤怠管理システムを導入していない会社で発生するリスクとは？
          </a>
        </p>
        <div id="toc2">
          <h1>勤怠管理の方法には何があるか</h1>
        </div>
        <img src="/images/column/details/c0002_g01.jpg" alt="キンクラコラム" />
        <p>
          社員ごとの勤怠情報を記録する方法や媒体には法律的な決まりはありません。ただし、厚生労働省のガイドライン「労働時間の適正な把握のために使用者が講ずべき措置」には、
          原則的な方法として、タイムカード、ＩＣカード、パソコンの使用時間の記録等の客観的な記録を基礎として
          確認し、適正に記録すること
          <br />
          やむを得ず自己申告制で労働時間を把握する場合には、十分な説明を行い、実態調査を実施し、適正な自己申告を阻害する措置を設けてはならない
          <br />
          と記載されています。どのような方法を利用したとしても、
          <span>労働時間を過少申告、過大申告をさせない仕組み</span>が必要です。
          <br />
          たとえば、手書き、専用機を用いた「タイムカード」、機器にかざすことで自動的に勤怠データが登録される「ICカード」、システムへのログイン記録がクラウド上で自動保存される「勤怠管理システム」などの方法を用いて、社員ごとの記録を入手する方法があり、会社の規模などによって利用しやすい方法を選ぶことができます。
        </p>
        <p>
          ↓ 勤怠管理のお勧めの方法についてはこちらで紹介しています。
          <br />
          <a href={routePram2} className="column-detail-link">
            関連記事：勤怠管理は経費精算付きのクラウドがおすすめ!
            その理由とは...
          </a>
        </p>
        <div id="toc3">
          <h1>各種勤怠管理システムのメリット・デメリット</h1>
        </div>
        <p>
          紙のタイムカードは、自分で表に記入するもの、時計と一体になった機械を利用して打刻するものがあります。メリットとしては、安価でどのような職場でも利用できますが、デメリットとしては、偽装が容易で客観性に欠ける場合があり、労働時間の集計が手作業で効率が悪くなることなどが挙げられます。
          <br />
          交通系のICカードや携帯に内蔵されたＩＣを利用したタイムカードは、偽装しづらいため客観性が高く、勤怠管理システムを利用することで労働時間の集計を自動的に行うことができるメリットがあります。デメリットとしてはシステムの導入や運用に費用がかかることなどが挙げられます。その他にも、パソコンからインターネットを経由して打刻する方法などがあります。
        </p>
        <p>
          ↓
          無料で使える勤怠管理システムのメリットについてこちらで詳しくご紹介していますので合わせて読んでみてください。
          <br />
          <a href={routePram3} className="column-detail-link">
            関連記事：手動での勤怠管理からの脱却!無料の勤怠管理システムのメリットとは?
          </a>
        </p>
        <a href={routePram4} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc4">
          <h1>就業管理との違いは？</h1>
        </div>
        <p>
          勤怠管理と似たような内容の一つに「就業管理」が挙げられますが、その内容は異なります。
          <br />
          勤怠管理が、賃金支払いのために義務付けられている労働時間管理であることに対し、就業管理とは、勤怠管理を行うことに加えて、
          <span>社員が安心安全に働ける環境にあるか否か</span>
          を全般的に管理していくことです。
          <br />
          具体的には、法律で定められた月あたりの残業時間をオーバーしていないか、変形労働時間制などのイレギュラーな働き方を導入している場合はその内容が守られているか、連日の休日出勤などで心身に支障をきたす働き方をしていないか、などの観点からチェックをしていきます。
          <br />
          <br />
          社員全体の働き方を就業管理で管理することで、社員が安心して働ける環境を整え、
          <span>企業の成長へつなげていく</span>というねらいがあります。
        </p>
        <div id="toc5">
          <h1>勤怠管理が必要とされる理由</h1>
        </div>
        <p>
          勤怠管理は、労働基準法という法律により使用者に向けて義務づけられています。正確な賃金支払いという目的はもちろん、長時間労働の抑制などを抑制や賃金の未払い問題を防ぐための管理であるともいえます。
          <br />
          なお、<span>勤怠管理情報が記載されたデータは5年間の保存</span>
          をしなければならず、実施していない場合は法律違反に問われるケースがあります。この「5年」のカウントは、最後に記録を行った日から起算して5年という形で行います。
          <br />
          <br />
          勤怠管理を怠ることは残業代の未払いや長時間労働を見過ごす事態へとつながる危険性があり、労使トラブルのもとになる状況に陥る可能性がありますので、覚えておきましょう。
        </p>
        <div id="toc6">
          <h1>勤怠管理体制の整備</h1>
        </div>
        <p>
          前述の通り、勤怠管理は使用者に課せられた義務であり、管理体制の整備は経営者にとって必須の対応です。
          <br />
          しかし、昨今の新型コロナウイルス感染症流行の影響により、テレワークや在宅勤務など、通常通り会社へ出社しないまま仕事をする社員が非常に増加しました。したがって、昔ながらのタイムカード打刻システムにより勤怠管理を行っていた会社などは、在宅勤務で社員が出社しない日の勤怠管理法について改めて検討する必要があるでしょう。
          <br />
          <br />
          対策としては、
          <span>
            パソコンシステムを活用したオンラインでの勤怠管理を行う方法を取る
          </span>
          ことが一般的ですが、特に自宅で仕事を行う在宅勤務の場合などは、プライベート空間と仕事の空間が同じ場所になることから、勤務時間が変則的になってしまう可能性があるため、気をつけなければなりません。たとえば、仕事を行う時間にバラつきが生じる場合や、オン・オフの切り替えがなく昼夜を問わずダラダラと仕事を続けてしまう場合、勤怠情報を社員が任意で入力し、実際は仕事をせずサボってしまう場合などが挙げられます。
          <br />
          このような状況に対応するため、最近では各社よりさまざまな勤怠管理システムが打ち出されています。たとえば、社員の仕事状況を一定期間ごとにチェックできるようにパソコン画面が定期的に保存され、システムへ送信されるシステムを活用することで、社員の仕事への意識を高める効果が生まれます。また、有給休暇の管理や残業申請書フォームと連携した機能のある勤怠システムや、交代制勤務のシフトを自動的に組み、管理できるシステムが搭載されているものもあります。
        </p>
        <img src="/images/column/details/c0002_g02.jpg" alt="キンクラコラム" />
        <div id="toc7">
          <h1 className="read">まとめ</h1>
        </div>
        <p>
          勤怠管理を正しく行うためには、使用者が守らなければならない法律の内容や、就業規則などで定められた社内ルールを熟知することが求められます。
          <br />
          現状の勤怠管理体制に不安がある場合などは、まずは
          <span>
            社内ルールを洗い出し、勤怠管理をスムーズに行うために必要な機能を検討
          </span>
          することから始めてみてはいかがでしょうか。
          <br />
          <br />
          雇用形態や働き方が多様化している昨今の時世に合わせた形で適切な勤怠管理を実施することが、会社の未来へとつながります。
        </p>
        <a href={routePram5} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0002
