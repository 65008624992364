import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  height: 20px;
  width: 20px;
  margin-bottom: 8px;
  .cls-1{
    fill: #FFF;
  }
`

/**
 * SVGアイコンコンポーネント
 * 製品サイトボタン用矢印アイコン
 */
const ArrowSvg = (): JSX.Element => (
  <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
              className="cls-1"
              d="M.66,3.05L5.48 3.05 10.95 10 5.48 16.95 .66 16.95 6.13 10 .66 3.05z"
          />
          <path
              className="cls-1"
              d="M9.05,3.05L13.87 3.05 19.34 10 13.87 16.95 9.05 16.95 14.52 10 9.05 3.05z"
          />
      </svg>
  </Wrapper>
)
export default ArrowSvg
