import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnBtnCard from '../atoms/columnBtnCard'
import ColumnH1 from '../atoms/columnH1'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
      display: none;
    }
  }

  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0002 = () => {
  const routePram = useRouteParam('/column/details/C0003/')

  return (
    <Wrapper>
      <ColumnH1 label="関連コラム" />
      <section>
        <ColumnBtnCard
          href={routePram}
          imgId="c0003.jpg"
          label="勤怠管理システムを導入したい！メリットや気を付けるべき点とは"
          date="2022.04.15"
        />
      </section>
    </Wrapper>
  )
}

export default ColumnRelationCards0002
