import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Button = styled.button`
  clear: both;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: ease 0.2s;

  :after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 11%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.4s, opacity 1s;
  }

  :active:after {
    transform: scale(0, 0);
    opacity: 0.5;
    transition: 0s;
  }

  &:hover {
    opacity: 1;
  }

  &.small {
    /* サービスサイト用調整中 */
    display: inline-flex;
    min-width: 90px;
    height: 34px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 100vh;
    line-height: 16px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }

  &.medium {
    /* サービスサイト用調整中 */
    display: inline-flex;
    min-width: 120px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 100vh;
    line-height: 16px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }

  &.large {
    /* サービスサイト用調整中 */
    display: inline-flex;
    min-width: 120px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 100vh;
    line-height: 16px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }

  ${BreakPoints.medium} {
    &.large {
      min-width: 50px;
      font-size: 16px;
      height: 34px;
      padding: 0 10px;
    }
  }

  &.mega {
    /* サービスサイト用調整済 */
    display: inline-flex;
    height: 85px;
    font-size: 30px;
    font-weight: 600;
    border-radius: 100vh;
    padding: 0 40px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  ${BreakPoints.large} {
    &.mega {
      height: 60px;
      font-size: 26px;
      padding: 0 10px;
    }
  }

  ${BreakPoints.smallest} {
    &.mega {
      height: 60px;
      font-size: 24px;
    }
  }

  &.weak {
    /* サービスサイト用調整済 */
    color: #00b5a6;
    background-color: #ffffff;
    border: solid 2px #19ba9b;
    overflow: hidden;
    transition: 0.2s;

    :before {
      content: '';
      z-index: -1;
      position: absolute;
      width: 110%;
      height: 101%;
      top: 0;
      right: -110%;
      background-color: #19ba9b;
      transition: 0.2s;
    }

    :hover {
      color: #ffffff;
      background-position: 0 105%;
      background-color: #19ba9b;
      transition: 0.22s;

      :hover:before {
        right: 0;
      }
    }
  }

  &.normal {
    /* サービスサイト用調整済 */
    color: #ffffff;
    background: #19ba9b;
    border: solid 2px #19ba9b;
    overflow: hidden;

    :before {
      content: '';
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: -100%;
      background-color: #ffffff;
      transition: 0.2s;
    }

    :hover {
      color: #19ba9b;
      background-position: 0 100%;

      :hover:before {
        right: 0;
      }
    }
  }

  &.strong {
    /* サービスサイト用調整中 */
    color: #ffffff;
    background: #ff9900;
    background: linear-gradient(
      to bottom right,
      #ff860d 15%,
      #ffbf00 75%,
      #ffff00 95%
    );
    background-size: 110% 110%;
    background-position: -2px -2px;
    border: solid 2px #ffbf00;

    :before {
      content: '';
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: -100%;
      background-color: #ff860d;
      transition: 0.2s;
    }

    :hover {
      transform: scale(1.1, 1.1);
      border-color: #ff860d;

      :hover:before {
        right: 0;
      }
    }
  }

  &.weak-caution {
    color: #ff5454;
    background-color: #ffffff;
    border: solid 1px #ff5454;
  }

  &.caution {
    color: #ffffff;
    background: #d62d2d;
    border: solid 1px #f78787;
  }

  &.disabled {
    color: rgba(210, 210, 210, 1);
    background: rgba(220, 220, 220, 0.3);
    border: solid 1px rgba(220, 220, 220, 1);

    &:hover {
      border-color: rgba(220, 220, 220, 0.8);
    }
  }
`

export type ICommonBtnProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  size?: 'small' | 'medium' | 'large' | 'mega'
  level?: 'weak' | 'normal' | 'strong' | 'weak-caution' | 'caution' | 'disabled'
}
/**
 * サービスサイト用共通ボタン
 * 強弱/大小で組み合わせて使うぞ
 * 立体感を出したくないときは各画面から.no-shadowを指定するようにする
 * @param props
 */
const OfficialCommonBtn = (props: ICommonBtnProps): JSX.Element => {
  const { className = '', size = 'small', level = 'weak' } = props
  return (
    <Button {...props} className={`${size} ${level} ${className}`}>
      {props.children}
    </Button>
  )
}
export default OfficialCommonBtn
