import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0002 from '../../../components/molecules/columnDetailInner0002'
import ColumnRelationCards0002 from '../../../components/molecules/columnRelationCards0002'
import UsefulFunctionLayout001 from '../../../components/molecules/usefulFunctionLayout001'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import CarouselBanner from '../../../components/molecules/carouselBanner'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0002 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '勤怠管理とは？コロナ禍における適切な管理方法について伝授',
    },
  ]

  // 0~8ランダム数字を保存
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 9))
  }, [])

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="勤怠管理とは？管理をする必要性や管理体制の整備について解説 "
        description="勤怠管理とは？管理が必要な理由、ワークライフバランスや働き方改革で多様化した働き方を適切に管理するにはどうすれば良いか、解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0002/"
        ogType="article"
        ogTitle="勤怠管理とは？管理をする必要性や管理体制の整備について解説 "
        ogDescription="勤怠管理とは？管理が必要な理由、ワークライフバランスや働き方改革で多様化した働き方を適切に管理するにはどうすれば良いか、解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0002.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0002 no={no} />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout001 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** カルーセルバナー */}
        <CarouselBanner />
        {/** 関連記事 */}
        <ColumnRelationCards0002 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0002

export const pageQuery = graphql`
  query C0002 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
